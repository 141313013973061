import { formatNonTimezoneDate } from '~/utils';
import { BookFillIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { useAppProcessing, useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { Divider, Flex, Text, GridAlpha } from '@applyboard/crystal-ui';
import { SingleDocumentRequest } from '~/pages/ApplicationDetails/components/ApplicationProcessing/AdditionalDocumentRequest/SingleDocumentRequest';

import { Card } from './Card';
import { AttachedDocuments } from './shared/AttachedDocuments';

export function LanguageProficiency() {
  const {
    application: { languageProficiency },
  } = useAppProcessing();
  const additionalDocumentRequest = useAdditionalDocumentRequest();

  return (
    <Card id="languageProficiency" icon={BookFillIcon} title="Language Proficiency">
      {languageProficiency.status === 'DONT_NEED' && (
        <Flex gap={4} direction="column">
          <Text variant="bodyM" contrast="mid">
            N/A
          </Text>
          {additionalDocumentRequest.isEnabled && (
            <SingleDocumentRequest
              documentTypeCode="LANGUAGE_TEST"
              documentTypeName="Language Proficiency Proof"
              mode="ADD"
              sectionReference="LANGUAGE_TEST"
            />
          )}
        </Flex>
      )}
      {languageProficiency.status === 'WILL_HAVE' && (
        <Flex gap={4} direction="column">
          <Text variant="bodyM" contrast="mid">
            To be provided.
          </Text>
          {additionalDocumentRequest.isEnabled && (
            <SingleDocumentRequest
              documentTypeCode="LANGUAGE_TEST"
              documentTypeName="Language Proficiency Proof"
              mode="ADD"
              sectionReference="LANGUAGE_TEST"
            />
          )}
        </Flex>
      )}
      {languageProficiency.status === 'HAVE' && (
        <Flex gap={6} direction="column">
          <LabelValue
            label="Test type"
            value={languageProficiency.languageProficiencyData.testType.title}
            variant="titleS"
          />
          <Flex gap={10}>
            <LabelValue
              label="Test Date"
              value={formatNonTimezoneDate(new Date(languageProficiency.languageProficiencyData.testDate))}
            />
            <Flex.Item>
              <LabelValue
                label={languageProficiency.languageProficiencyData.certificateNumber.title}
                value={languageProficiency.languageProficiencyData.certificateNumber.value}
              />
            </Flex.Item>
          </Flex>
          <Divider />
          <LabelValue
            label={languageProficiency.languageProficiencyData.overallScore.title}
            value={languageProficiency.languageProficiencyData.overallScore.value}
            variant="labelL"
            color="primary"
          />
          <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(5, 1fr)">
            <>
              {languageProficiency.languageProficiencyData.subscores.map((s) => (
                <LabelValue key={s.title} label={s.title} value={s.formattedValue} variant="titleS" />
              ))}
            </>
            <GridAlpha.Item rowStart={2} rowEnd={2} columnStart={1} columnEnd={5}>
              <AttachedDocuments
                attachmentDocuments={languageProficiency.languageProficiencyData.documents}
                section="languageProficiency"
                sectionReference={languageProficiency.languageProficiencyData.key}
                noRequestForMissingDocuments
              />
            </GridAlpha.Item>
          </GridAlpha>
        </Flex>
      )}
    </Card>
  );
}
